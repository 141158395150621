import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import moment from "moment"
import ReactMarkdown from "react-markdown/with-html"
import Likes from "../components/likes"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allStrapiPosts.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Publicaciones" />
      <Bio />
      {posts.map(({ node }) => {
        const title = node.title || node.slug
        return (
          <article key={node.slug}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.slug}>
                  {title}
                </Link>
              </h3>
              <small>
                {moment(node.updated_at).local().format(`DD-MM-YYYY HH:mm`)}
              </small>
              <Likes slug={node.slug} />
            </header>
            <section>
              <ReactMarkdown>
                {node.body.split("\n")[0].split("##")[1]}
              </ReactMarkdown>
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiPosts(
      sort: { order: DESC, fields: updated_at }
      filter: { status: { eq: "published" }, slug: { ne: "sobre-mi" } }
    ) {
      edges {
        node {
          id
          title
          slug
          body
          user {
            username
          }
          updated_at
          created_at
        }
      }
    }
  }
`
